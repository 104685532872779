import * as React from 'react'
import { Route, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import RouteGroupSwitch from 'libs/RouteGroupSwitch'
import { NavigationProvider } from 'contexts/NavigationContext'

import Callback from 'containers/CallbackPageContainer'
import Login from 'containers/LoginPageContainer'
import Dashboard from 'containers/DashboardPageContainer'
import Main from 'containers/MainPageContainer'
import { hooks } from 'libs'
import PrescriptionsPageContainer from 'containers/PrescriptionsPageContainer'
import InProcessPrescriptionsPageContainer from 'containers/InProcessPrescriptionsPageContainer'
import DonePrescriptionsPageContainer from 'containers/DonePrescriptionsPageContainer'
import PrescriptionDetailPageContainer from 'containers/PrescriptionDetailPageContainer'
import AdminPharmacyContainer from 'containers/AdminPharmacyContainer'

export function PublicRoutes() {
  return (
    <>
      <Route path="/callback" element={<Callback />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to="login" replace />} />
    </>
  )
}

export function PrivateRoutes() {
  window.scrollTo(0, 0)
  return (
    <Route element={<NavigationProvider />}>
      <Route path="/callback" element={<Callback />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route element={<Main />}>
        <Route path="/:shopId/admin" element={<AdminPharmacyContainer />} />
        <Route path={'/:shopId/main/prescriptions'} Component={PrescriptionsPageContainer}>
          <Route path="/:shopId/main/prescriptions/in-process" element={<InProcessPrescriptionsPageContainer />} />
          <Route path="/:shopId/main/prescriptions/done" element={<DonePrescriptionsPageContainer />} />
          <Route
            path="/:shopId/main/prescriptions/in-process/:prescriptionId"
            element={<PrescriptionDetailPageContainer />}
          />
          <Route
            path="/:shopId/main/prescriptions/done/:prescriptionId"
            element={<PrescriptionDetailPageContainer />}
          />
        </Route>
      </Route>
    </Route>
  )
}

export const Router = () => {
  const { authenticated: isLoggedIn } = hooks.useAuth()
  return (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route element={<RouteGroupSwitch />}>{isLoggedIn ? PrivateRoutes() : PublicRoutes()}</Route>,
        ),
      )}
    />
  )
}
