import React, { useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { hooks } from 'libs'
import { useLocation } from 'react-router-dom'
import { Domain } from 'core'
import {
  PrescriptionInfoSection,
  PrescriptionActionList,
  PrescriptionImageViewer,
  ConfirmModal,
  Portal,
} from 'components/organisms'
import { Box, CommonButton, Table, TableBody, TableCell, TableContainer, TableRow } from 'components/atoms'
import icoArrowBack from 'assets/images/ico_arrow_back.svg'
import { ModalStatus } from 'components/organisms/PrescriptionActionList'

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const StyledCommonButton = styled(CommonButton)(({ theme }) => ({
  height: 42,
  background: '#2196F3',
  boxShadow: theme.shadows[1],
  lineHeight: theme.typography.pxToRem(20),
  color: '#FFFFFF',
}))

const BreakPage = styled(Box)(({}) => ({
  pageBreakBefore: 'always',
}))

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const StyledTableBody = styled(TableBody)(({}) => ({
  padding: 0,
}))

const StyledTableRow = styled(TableRow)({
  borderWidth: 0,
})

const StyledTableHeader = styled(TableCell)({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
})

const StyledTableData = styled(TableCell)({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
})

const StyledTableEmpty = styled(TableCell)(({ theme }) => ({
  borderWidth: 0,
  padding: 0,
  paddingRight: theme.spacing(1),
}))

const PrintImageWrapper = styled(Box)({
  maxWidth: '90%',
  margin: 'auto',
})

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
})

/**
 * Interface
 */

interface PrescriptionDetailPageProps {
  prescription: Prescription | null
  changeStatus: (status: string) => void
  changeStatusWithMinute: (status: string, minute: number) => void
}

type Prescription = Domain.Prescription.Prescription

type ModalLabels = Record<ModalStatus, { title: string; description: string }>
const ModalLabels: ModalLabels = {
  dispensing: {
    title: '通知をします',
    description: '「ご注文を承りました」を通知します。よろしいですか？',
  },
  dispensed: {
    title: '通知をします',
    description: '「ご用意ができました」を通知します。よろしいですか？',
  },
  completed: {
    title: 'この処理を完了します',
    description: 'この処方せんに関して、すべての作業を完了とします',
  },
  forceCompleted: {
    title: 'この処理を完了します',
    description: 'この処方せんに関して、途中の状態でもすべての工程を完了とします',
  },
  canceled: {
    title: 'この処理を中断します',
    description: 'この処方せんに関して、すべての作業を中断します',
  },
  initial: {
    title: '',
    description: '',
  },
}

export default function PrescriptionDetailPage(props: PrescriptionDetailPageProps) {
  const { prescription, changeStatus, changeStatusWithMinute } = props

  const [time, setTime] = useState(30)
  const [isOpened, setIsOpened] = useState(false)
  const [modalStatus, setModalStatus] = useState<ModalStatus>('initial')
  const { navigatePop } = hooks.useAppNavigate()
  const location = useLocation()

  const isInProcess = useMemo(() => {
    return location.pathname.indexOf('in-process') !== -1
  }, [location])

  const closeModal = () => {
    setModalStatus('initial')
    setIsOpened(false)
  }

  /*
   *   PrescriptionActionList 関数のmax引数について
   *  「60分以上」項目が追加されたため、60分+インターバル5分の[65]を設定
   */
  return (
    <Box>
      <Root display="block" displayPrint="none">
        <Box fontSize={34} marginTop={3}>
          {isInProcess ? '処理中の処方せん詳細' : '完了した処方せん詳細'}
        </Box>
        <Box marginTop={4}>
          <PrescriptionInfoSection prescription={prescription} />
        </Box>
        <StyledDiv>
          <PrescriptionImageViewer
            images={prescription && prescription.prescriptionImageUrls ? prescription.prescriptionImageUrls : []}
            onPrint={() => {
              window.print()
            }}
          />
        </StyledDiv>
        <Box marginTop={2}>
          <PrescriptionActionList
            prescription={prescription}
            minutes={[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120, 128]}
            threshold={120}
            time={time}
            setTime={(time: number) => {
              setTime(time)
            }}
            openModal={(modalStatus: ModalStatus) => {
              setIsOpened(true)
              setModalStatus(modalStatus)
            }}
          />
        </Box>
        <Box width="100%" display="flex" justifyContent="flex-end" marginTop={2}>
          <StyledCommonButton type={'primary'} label={'戻る'} icon={icoArrowBack} click={navigatePop} />
        </Box>
      </Root>
      <Box display="none" displayPrint="block">
        <PrescriptionInfoSection prescription={prescription} />
        {prescription &&
          prescription.prescriptionImageUrls.map((url, index) => {
            return (
              <BreakPage key={index}>
                <StyledTableContainer>
                  <Table aria-label="simple table">
                    <StyledTableBody>
                      <StyledTableRow>
                        <StyledTableHeader component="th" scope="row">
                          受付番号
                        </StyledTableHeader>
                        <StyledTableData align="left">{prescription?.acceptedCode}</StyledTableData>
                        <StyledTableEmpty />
                        <StyledTableHeader component="th" scope="row">
                          ユーザー名
                        </StyledTableHeader>
                        <StyledTableData align="left">{prescription?.name}</StyledTableData>
                      </StyledTableRow>
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
                <PrintImageWrapper display={'flex'} justifyContent={'center'} padding={2}>
                  <StyledImage src={url} />
                </PrintImageWrapper>
              </BreakPage>
            )
          })}
      </Box>
      <Portal targetId={'modal'}>
        <ConfirmModal
          isOpened={isOpened}
          title={ModalLabels[modalStatus].title}
          description={ModalLabels[modalStatus].description}
          cancelButtonLabel={'キャンセル'}
          onClickDone={() => {
            const mStatus: Exclude<ModalStatus, 'forceCompleted'> =
              modalStatus === 'forceCompleted' ? 'completed' : modalStatus
            mStatus === 'dispensing' ? changeStatusWithMinute(mStatus, time) : changeStatus(mStatus)
            closeModal()
          }}
          onClickCancel={closeModal}
        />
      </Portal>
    </Box>
  )
}
