export type MedicineDesignation = "Generic" | "Original"

export interface Prescription {
  readonly id: number
  readonly shopName: string
  readonly acceptedAt: string
  readonly status: string
  readonly pharmacyId: string
  readonly isGenericMedicine?: boolean
  readonly hasPrescriptionNote: boolean
  readonly isPregnancy: boolean
  readonly allergy: string
  readonly isVisitToday?: boolean
  readonly medicineDesignation?: MedicineDesignation
  readonly needsNotification?: boolean
  readonly weight?: number
  readonly tel: string
  readonly acceptedCode: string
  readonly name: string
  readonly completedAt?: string
  readonly isBreastFeeding: boolean
  readonly isExpireSoon: boolean
  readonly imageCount: number
  readonly prescriptionImageUrls: Array<string>
  readonly isRead: boolean
  // status 変更時の場合のみ、エラー文言を受け取る
  readonly errorMessage: string | null
}

const sortKeyList = ['default', 'acceptedCode', 'acceptedAt', 'name', 'imageCount', 'checkList', 'status'] as const
type SortKey = typeof sortKeyList[number]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSortKey = (key: any): key is SortKey => {
  return sortKeyList.includes(key)
}

export const filterKeyList = ['all', 'new', 'dispensing', 'dispensed', 'warning'] as const
type FilterKey = typeof filterKeyList[number]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFilterKey = (key: any): key is FilterKey => {
  return filterKeyList.includes(key)
}

export interface SortOrder {
  sortKey: SortKey
  order: 'asc' | 'desc'
}

export interface PrescriptionDisplayConditions {
  sortOrderForInProcess: SortOrder
  sortOrderForDone: SortOrder
  filterForInProcess: FilterKey
}