import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Domain } from 'core'
import { SectionPanel } from 'components/molecules'
import { Typography, Box, TableContainer, Table, TableRow, TableCell, TableBody, StatusChip } from 'components/atoms'
import icoGeneric from 'assets/images/ico_generic.svg'
import icoOriginal from 'assets/images/ico_original.svg'
import icoBook from 'assets/images/ico_book.svg'
import icoMaternity from 'assets/images/ico_maternity.svg'
import icoFeeding from 'assets/images/ico_feeding.svg'
import icoNone from 'assets/images/ico_none.svg'
import { date } from 'libs'
import * as COLORS from '../../../constants/color'
import hospitalVisitIcon from '../../../assets/images/hospital-visit.svg'

const Content = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(54)})`,
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderTopWidth: 1,
}))

const SectionHead = styled(Typography)({
  fontWeight: 'normal',
})

const StyledTableBody = styled(TableBody)({
  padding: 0,
})

const StyledTableRow = styled(TableRow)({
  borderWidth: 0,
})

const StyledTableHeader = styled(TableCell)({
  width: '124px',
  borderStyle: 'solid',
  backgroundColor: COLORS.TOMODS_GRAY_300,
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
})

const StyledTableData = styled(TableCell)<{ isFix?: boolean }>(({ isFix = false }) => ({
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  width: isFix ? '380px' : 'auto',
}))

const StyledTableEmpty = styled(TableCell)(({ theme }) => ({
  // レイアウト調整のため固定
  width: 22,
  borderWidth: 0,
  padding: 0,
  paddingRight: theme.spacing(1),
}))

const CheckItemList = styled(TableCell)({
  flexDirection: 'column',
  borderStyle: 'solid',
  borderColor: '#E0E0E0',
  borderWidth: 0,
  borderBottomWidth: 1,
  padding: 0,
})

const Icon = styled('img')({
  width: 28,
  height: 28,
})

/**
 * Interface
 */

export interface PrescriptionInfoSectionProps {
  prescription: Prescription | null
}

type Prescription = Domain.Prescription.Prescription

export interface PrescriptionPreConfirmChecks {
  isGenericMedicine?: boolean
  hasPrescriptionNote: boolean
  isPregnancy: boolean
  isBreastFeeding: boolean
}

export const PreConfirms = {
  isGenericMedicine: { label: 'ジェネリック医薬品を希望する', icon: `${icoGeneric}` },
  hasPrescriptionNote: { label: 'おくすり手帳をもってくる', icon: `${icoBook}` },
  isPregnancy: { label: '妊娠中である', icon: `${icoMaternity}` },
  isBreastFeeding: { label: '授乳中である', icon: `${icoFeeding}` },
} as const

type PreConfirmsKey = keyof typeof PreConfirms

const checkItem = (key: PreConfirmsKey, prescription: Prescription | null) => {
  if (prescription == null) return false
  // 事前確認ページ改修タスク前は、ジェネリック医薬品を希望するかどうかをisGenericMedicineで判断していたが、改修後はmedicineDesignationで判断するようになったため、isGenericMedicineとmedicineDesignationの両方で判定するようにする。
  if (key === 'isGenericMedicine')
    return prescription.isGenericMedicine || prescription.medicineDesignation === 'Generic'
  return prescription[key]
}

export default function PrescriptionInfoSection(props: PrescriptionInfoSectionProps) {
  const { prescription } = props

  const [acceptedAt, setAcceptedAt] = useState('-')
  const [completedAt, setCompletedAt] = useState('-')

  const getVisitingText = (prescription: Prescription) => {
    const acceptDate = new Date(prescription.acceptedAt)
    // 次の日を取得したい
    const nextDate = new Date(acceptDate)
    nextDate.setDate(nextDate.getDate() + 1)
    const formattedDate = `${nextDate.getMonth() + 1}月${nextDate.getDate()}日`

    return prescription.isVisitToday ? '本日来局予定' : `${formattedDate} 以降来局`
  }

  useEffect(() => {
    if (prescription) {
      setAcceptedAt(date.formatDate(prescription.acceptedAt))
      if (prescription.completedAt) {
        setCompletedAt(date.formatDate(prescription.completedAt))
      }
    }
  }, [prescription])

  return (
    <Box>
      <SectionPanel header={<SectionHead variant="h6">入力内容</SectionHead>}>
        <Content paddingLeft={2} paddingRight={2} paddingBottom={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <StyledTableBody>
                <StyledTableRow>
                  <StyledTableHeader component="th" scope="row">
                    受付番号
                  </StyledTableHeader>
                  <StyledTableData isFix={true} align="left">
                    {prescription?.acceptedCode}
                  </StyledTableData>
                  <StyledTableEmpty />
                  <StyledTableHeader component="th" scope="row">
                    ステータス
                  </StyledTableHeader>
                  <StyledTableData align="left">
                    {prescription?.status ? <StatusChip status={prescription.status} /> : null}
                  </StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableHeader component="th" scope="row">
                    受付時間
                  </StyledTableHeader>
                  <StyledTableData isFix={true} align="left">
                    {acceptedAt}
                  </StyledTableData>
                  <StyledTableEmpty />
                  <StyledTableHeader component="th" scope="row">
                    完了時間
                  </StyledTableHeader>
                  <StyledTableData align="left">{completedAt}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableHeader component="th" scope="row">
                    電話番号
                  </StyledTableHeader>
                  <StyledTableData isFix={true} align="left">
                    {prescription?.tel}
                  </StyledTableData>
                  <StyledTableEmpty />
                  <StyledTableHeader component="th" scope="row">
                    ユーザー名
                  </StyledTableHeader>
                  <StyledTableData align="left">{prescription?.name}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableHeader component="th" scope="row">
                    来局予定
                  </StyledTableHeader>
                  <StyledTableData isFix={true} align="left">
                    <Box display="flex" alignItems="center">
                      {prescription && (
                        <>
                          <Icon src={hospitalVisitIcon} />
                          <Typography
                            variant="caption"
                            color={prescription.isVisitToday ? COLORS.TOMODS_BLACK : COLORS.TOMODS_RED_800}>
                            {getVisitingText(prescription)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </StyledTableData>
                  <StyledTableEmpty />
                  <StyledTableHeader component="th" scope="row">
                    体重(kg)
                  </StyledTableHeader>
                  <StyledTableData align="left">{prescription?.weight}</StyledTableData>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableHeader component="th" scope="row">
                    チェック項目
                  </StyledTableHeader>
                  <CheckItemList>
                    {Object.entries(PreConfirms).map(([key, preConfirm], index) => {
                      const preConfirmKey = key as PreConfirmsKey
                      const isChecked = checkItem(preConfirmKey, prescription)
                      return (
                        <Box key={key} borderTop={index === 0 ? 0 : 1} borderColor={'#E0E0E0'}>
                          {preConfirmKey === 'isGenericMedicine' ? (
                            <Box display="flex" alignItems="center" padding={2}>
                              {prescription && (
                                <>
                                  <Icon src={isChecked ? icoGeneric : icoOriginal} />
                                  <Typography variant="caption">
                                    {isChecked ? 'ジェネリック医薬品' : '先発薬'}を希望する
                                  </Typography>
                                </>
                              )}
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center" padding={2}>
                              <Icon src={isChecked ? preConfirm.icon : icoNone} />
                              {isChecked && <Typography variant="caption">{preConfirm.label}</Typography>}
                            </Box>
                          )}
                        </Box>
                      )
                    })}
                  </CheckItemList>
                  <StyledTableEmpty />
                  <StyledTableHeader component="th" scope="row">
                    アレルギー・その他連絡事項
                  </StyledTableHeader>
                  <StyledTableData align="left">{prescription?.allergy}</StyledTableData>
                </StyledTableRow>
              </StyledTableBody>
            </Table>
          </TableContainer>
        </Content>
      </SectionPanel>
    </Box>
  )
}
