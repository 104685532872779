import shadows from './shadows'

export type ButtonTypes = 'primary' | 'secondary' | 'cancel' | 'forceCompleted'
type ButtonOptions = Record<ButtonTypes, React.CSSProperties>

const buttons: ButtonOptions = {
  primary: {
    background: '#2196F3',
    boxShadow: shadows[1],
    color: '#FFFFFF !important',
  },
  secondary: {
    background: '#FFFFFF',
    color: '#000000DE',
    borderColor: '#0000003B',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  cancel: {
    background: '#F44336',
    boxShadow: shadows[1],
    color: '#FFFFFF !important',
  },
  forceCompleted: {
    background: '#4CAF50',
    boxShadow: shadows[1],
    color: '#FFFFFF !important',
  },
}

export default buttons
