const COPYRIGHT = 'Classmethod, Inc.'

/**
 * 調剤ステータス
 */
const PRESCRIPTION_STATUS = {
  ALL: '全て',
  NEW: '未処理',
  DISPENSING: '調剤中',
  DISPENSED: '調剤完了',
  COMPLETED: '会計完了',
  CANCELED: '取消',
  WARNING: '期限間近',
}


export {
  COPYRIGHT,
  PRESCRIPTION_STATUS,
}
