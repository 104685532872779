import { createTheme } from '@mui/material/styles'

import paletteOption from 'styles/palette'
import typographyOption from 'styles/typography'
import breakpointsOption from 'styles/breakpoints'
import shadowsOption from 'styles/shadows'
import buttonOption from 'styles/button'

export const tomodsMcTheme = createTheme({
  breakpoints: breakpointsOption,
  typography: typographyOption,
  palette: paletteOption,
  shadows: shadowsOption,
  button: buttonOption,
})
