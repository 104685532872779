import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Usecases } from 'core'
import { hooks } from 'libs'
import MainPage from 'components/screens/MainPage'
import { usePlayNewArrivalNotification } from 'libs/hooks/usePlayNotification'
import { setShopId } from 'core/modules/currentShop'
import { useAppDispatch } from 'libs/hooks'

export default function MainPageContainer() {
  const location = useLocation()
  const { shopId = '' } = useParams<{ shopId: string }>()
  const token = hooks.useAuth().idToken
  const { play, cleanUpFunc } = usePlayNewArrivalNotification()
  const { openWebSocket, closeWebSocket } = hooks.useNotification({ playNewArrivalNotification: play })

  const dispatch = useAppDispatch()

  const [prevPath, setPrevPath] = useState<string | null>(null)

  useEffect(() => {
    let sendApiFlg = false
    const currentPath = location.pathname
    const pageNames = ['in-process', 'done']
    const pagePaths = pageNames.map(pageName => `/${shopId}/main/prescriptions/${pageName}`)
    // 各ページ一覧更新条件
    if (
      // 一つ前のページパスがあること
      prevPath &&
      // 現在のページパスが対象ページパスのいずれかであること
      pagePaths.includes(currentPath) &&
      // 一つ前のページパスがあり、かつ対象ページパスの関連ページであること
      pagePaths.some(pagePath => prevPath.startsWith(pagePath)) &&
      // 現在のページパスよりも、一つ前のページパスの方が長いこと
      currentPath.split('/').length < prevPath.split('/').length
    ) {
      sendApiFlg = true
    }
    setPrevPath(currentPath)
    if (!sendApiFlg) return
    dispatch(Usecases.getInProcessPrescriptions.sendGetInProcessPrescriptions(token, shopId))
    dispatch(Usecases.getDonePrescriptions.sendGetDonePrescriptions(token, shopId))
  }, [location.pathname])

  useEffect(() => {
    dispatch(setShopId(shopId))
    dispatch(Usecases.getInProcessPrescriptions.sendGetInProcessPrescriptions(token, shopId))
    dispatch(Usecases.getDonePrescriptions.sendGetDonePrescriptions(token, shopId))
  }, [])

  useEffect(() => {
    if (token) openWebSocket(token, shopId)

    return () => {
      closeWebSocket()
    }
  }, [token, closeWebSocket])

  return <MainPage notificationCleanUpFunc={cleanUpFunc} />
}
