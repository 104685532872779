import { createAction, createReducer } from '@reduxjs/toolkit'
import { Domain } from 'core'

type PrescriptionDisplayConditions = Domain.Prescription.PrescriptionDisplayConditions

type State = { prescriptionDisplayConditions: PrescriptionDisplayConditions }

const initialState: PrescriptionDisplayConditions = {
  sortOrderForInProcess: { sortKey: 'default', order: 'asc' },
  sortOrderForDone: { sortKey: 'default', order: 'asc' },
  filterForInProcess: 'all',
}

export function createInitialState(): State {
  return { prescriptionDisplayConditions: initialState }
}

const SET_PRESCRIPTION_DISPLAY_CONDITIONS = 'display-conditions/set' as const
const CLEAR_PRESCRIPTION_DISPLAY_CONDITIONS = 'display-conditions/clear' as const

export const setPrescriptionDisplayConditions = createAction<PrescriptionDisplayConditions>(
  SET_PRESCRIPTION_DISPLAY_CONDITIONS,
)
export const clearPrescriptionDisplayConditions = createAction(CLEAR_PRESCRIPTION_DISPLAY_CONDITIONS)

export const reducer = createReducer(createInitialState(), builder => {
  builder
    .addCase(setPrescriptionDisplayConditions, (state, action) => {
      state.prescriptionDisplayConditions = action.payload
    })
    .addCase(clearPrescriptionDisplayConditions, state => {
      state.prescriptionDisplayConditions = initialState
    })
    .addDefaultCase(() => {
      return
    })
})
