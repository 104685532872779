import React from 'react'
import { styled } from '@mui/material/styles'
import { useAppSelector } from 'libs/hooks'
import { RootState } from 'core/store'
import { hooks } from 'libs'
import { Typography, List, Box } from 'components/atoms'
import SidebarMenuItem from 'components/molecules/SidebarMenuItem'
import icoList from 'assets/images/ico_list.svg'
import icoDoneList from 'assets/images/ico_done_list.svg'

const Root = styled('div')({
  height: '100%',
  backgroundColor: '#FAFAFA',
})

const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: theme.typography.pxToRem(32),
  opacity: 0.54,
}))

export default function SidebarMenu() {
  const shopId = useAppSelector((state: RootState) => state.currentShop.shopId)
  const { navigate } = hooks.useAppNavigate()

  const inProcessPrescriptions = useAppSelector((state: RootState) => state.inProcessPrescriptions.prescriptions) ?? []

  return (
    <Root>
      <Box pl={2} pr={2} pt={2} pb={1}>
        <StyledTypography variant="overline" display="block">
          処方せん
        </StyledTypography>
      </Box>
      <Box>
        <List component="nav" disablePadding>
          <SidebarMenuItem
            title={`処理中の処方せん一覧 (${inProcessPrescriptions.length})`}
            iconPath={icoList}
            onClick={() => navigate(`/${shopId}/main/prescriptions/in-process`)}
          />
          <SidebarMenuItem
            title={'完了した処方せん一覧'}
            iconPath={icoDoneList}
            onClick={() => navigate(`/${shopId}/main/prescriptions/done`)}
          />
        </List>
      </Box>
      <Box pl={2} pr={2} pt={60} pb={1}>
        <StyledTypography variant="overline" display="block">
          店舗管理
        </StyledTypography>
      </Box>
      <Box>
        <List component="nav" disablePadding>
          <SidebarMenuItem title={`店舗情報`} iconPath={icoList} onClick={() => navigate(`/${shopId}/admin`)} />
        </List>
      </Box>
    </Root>
  )
}
