import { configureStore } from '@reduxjs/toolkit'
import {
  CurrentShop,
  DonePrescriptions,
  ErrorModule,
  HasNotification,
  HasNotCompatibleNotification,
  InProcessPrescriptions,
  Network,
  PrescriptionDetail,
  PrescriptionDisplayConditions,
  SocketErrorModule,
  ShopInformation,
} from 'core/modules'

export const store = configureStore({
  reducer: {
    error: ErrorModule.reducer,
    socketError: SocketErrorModule.reducer,
    network: Network.reducer,
    donePrescriptions: DonePrescriptions.reducer,
    inProcessPrescriptions: InProcessPrescriptions.reducer,
    prescriptionDetail: PrescriptionDetail.reducer,
    currentShop: CurrentShop.reducer,
    prescriptionDisplayConditions: PrescriptionDisplayConditions.reducer,
    hasNotification: HasNotification.reducer,
    shopInformation: ShopInformation.reducer,
    hasNotCompatibleNotification: HasNotCompatibleNotification.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
