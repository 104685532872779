import React from 'react'
import { styled } from '@mui/material/styles'
import { Domain } from 'core'
import { hooks } from 'libs'
import { PrescriptionListSection } from 'components/organisms'
import { Box, Typography } from 'components/atoms'
import { LoadingPanel, PageHeader } from 'components/molecules'

const StyledBox = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.typography.pxToRem(106)})`,
  paddingBottom: theme.spacing(2),
}))

/**
 * interface
 */

interface DonePrescriptionsPageProps {
  shopId: string | null
  prescriptions?: Array<Prescription>
  handleOrderChange: (sortKey: string) => void
  handlePrescriptionsUpdate: () => void
  sortOrder: SortOrder
}

type Prescription = Domain.Prescription.Prescription
type SortOrder = Domain.Prescription.SortOrder

export default function DonePrescriptionsPage(props: DonePrescriptionsPageProps) {
  const { navigate } = hooks.useAppNavigate()

  return (
    <Box>
      <PageHeader>
        <Typography variant="h1" component="h2">
          完了した処方せん
        </Typography>
      </PageHeader>
      <StyledBox>
        {props.prescriptions != null ? (
          <PrescriptionListSection
            prescriptions={props.prescriptions}
            onClick={(prescriptionId: string) => navigate(`/${props.shopId}/main/prescriptions/done/${prescriptionId}`)}
            sortOrder={props.sortOrder}
            handleOrderChange={props.handleOrderChange}
            handlePrescriptionsUpdate={props.handlePrescriptionsUpdate}
          />
        ) : (
          <LoadingPanel />
        )}
      </StyledBox>
    </Box>
  )
}
